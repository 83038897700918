import React from "react";
import { createUseStyles } from "react-jss";
import PropType from "prop-types";

const H1 = "h1";
const H2 = "h2";
const H3 = "h3";
const H4 = "h4";
const H5 = "h5";
const H6 = "h6";

const useStyle = createUseStyles((theme) => ({
  textbase: {
    textAlign: (props) => props.align,
    margin: (props) => props.margin,
    marginTop: (props) => props.marginTop,
    marginBottom: (props) => props.marginBottom,
    marginLeft: (props) => props.marginLeft,
    marginRight: (props) => props.marginRight,
    padding: (props) => props.padding,
    background: (props) => props.background,
    fontWeight: (props) => props.weight
  },
  heading1: {
    margin: 0,
    fontSize: (props) =>
      props.fontSize ? props.fontSize : theme.font.h1.fontSize,
    fontWeight: theme.font.h1.fontWeight,
    fontFamily: theme.font.h1.fontFamily,
    color: (props) =>
      theme.font.colors[props.color]
        ? theme.font.colors[props.color]
        : props.color,
  },
  heading2: {
    margin: 0,
    fontSize: (props) =>
      props.fontSize ? props.fontSize : theme.font.h2.fontSize,
    fontWeight: theme.font.h2.fontWeight,
    fontFamily: theme.font.h2.fontFamily,
    color: (props) =>
      theme.font.colors[props.color]
        ? theme.font.colors[props.color]
        : props.color,
  },
  heading3: {
    margin: 0,
    fontSize: (props) =>
      props.fontSize ? props.fontSize : theme.font.h3.fontSize,
    fontWeight: theme.font.h3.fontWeight,
    fontFamily: theme.font.h3.fontFamily,
    color: (props) =>
      theme.font.colors[props.color]
        ? theme.font.colors[props.color]
        : props.color,
  },
  heading4: {
    margin: 0,
    fontSize: (props) =>
      props.fontSize ? props.fontSize : theme.font.h4.fontSize,
    fontWeight: theme.font.h4.fontWeight,
    fontFamily: theme.font.h4.fontFamily,
    color: (props) =>
      theme.font.colors[props.color]
        ? theme.font.colors[props.color]
        : props.color,
  },
  heading5: {
    margin: 0,
    fontSize: (props) =>
      props.fontSize ? props.fontSize : theme.font.h5.fontSize,
    fontWeight: theme.font.h5.fontWeight,
    fontFamily: theme.font.h5.fontFamily,
    color: (props) =>
      theme.font.colors[props.color]
        ? theme.font.colors[props.color]
        : props.color,
  },
  heading6: {
    margin: 0,
    fontSize: (props) =>
      props.fontSize ? props.fontSize : theme.font.h6.fontSize,
    fontWeight: theme.font.h6.fontWeight,
    fontFamily: theme.font.h6.fontFamily,
    color: (props) =>
      theme.font.colors[props.color]
        ? theme.font.colors[props.color]
        : props.color,
  },
  body: {
    margin: 0,
    fontSize: (props) =>
      props.fontSize ? props.fontSize : theme.font.body.fontSize,
    fontWeight: theme.font.body.fontWeight,
    fontFamily: theme.font.body.fontFamily,
    color: (props) =>
      theme.font.colors[props.color]
        ? theme.font.colors[props.color]
        : props.color,
  },
  subText: {
    margin: 0,
    fontSize: (props) =>
      props.fontSize ? props.fontSize : theme.font.subText.fontSize,
    fontWeight: theme.font.subText.fontWeight,
    fontFamily: theme.font.subText.fontFamily,
    color: (props) =>
      theme.font.colors[props.color]
        ? theme.font.colors[props.color]
        : props.color,
  },
  "body-blog": {
    margin: 0,
    fontSize: (props) =>
      props.fontSize ? props.fontSize : theme.font.subText.fontSize,
    fontWeight: theme.font.subText.fontWeight,
    fontFamily: theme.font.subText.fontFamily,
    color: (props) =>
      theme.font.colors[props.color]
        ? theme.font.colors[props.color]
        : props.color,
  },
}));

/**
 * generic text component for handling the text in the ui
 * @param {*} param0
 */
const Text = ({
  varient = "body",
  color = "default",
  align = "left",
  className = "",
  style,
  fontSize,
  weight,
  children,
  ...others
}) => {
  const classes = useStyle({ color, align, weight, fontSize, ...others });

  function mClass(pClass) {
    return `${pClass} ${classes.textbase} ${className}`;
  }

  switch (varient) {
    case H1:
      return (
        <h1 className={mClass(classes.heading1)} style={style}>
          {children}
        </h1>
      );
    case H2:
      return (
        <h2 className={mClass(classes.heading2)} style={style}>
          {children}
        </h2>
      );
    case H3:
      return (
        <h3 className={mClass(classes.heading3)} style={style}>
          {children}
        </h3>
      );
    case H4:
      return (
        <h4 className={mClass(classes.heading4)} style={style}>
          {children}
        </h4>
      );
    case H5:
      return (
        <h5 className={mClass(classes.heading5)} style={style}>
          {children}
        </h5>
      );
    case H6:
      return (
        <h6 className={mClass(classes.heading6)} style={style}>
          {children}
        </h6>
      );
    default:
      return (
        <p className={mClass(classes[varient])} style={style}>
          {children}
        </p>
      );
  }
};

/**
 * TODO: add the typescript support to make this use full
 */
Text.propTypes = {
  varient: PropType.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "body",
    "subText",
    "body-blog"
  ]),
  color: PropType.oneOf([
    "primary",
    "default",
    "light",
    "hard",
    "danger",
    "link",
  ]),
  style: PropType.object,
  className: PropType.string,
};

export default Text;
